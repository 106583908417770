export default [
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('@/views/schedule/Schedule.vue'),
    meta: {
      title: 'schedule ',
      requiresAuth: true,
      action: 'browes',
      resource: 'schedule',
      pageTitle: 'schedule',
      breadcrumb: [
        {
          text: 'schedule',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/schedule-pending',
    name: 'schedule-pending',
    component: () => import('@/views/schedule/SchedulePending.vue'),
    meta: {
      title: 'Pending & Rejected ',
      requiresAuth: true,
      action: 'browes',
      resource: 'schedule-pending',
      pageTitle: 'Pending & Rejected',
      breadcrumb: [
        {
          text: 'Pending & Rejected',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/schedule/add',
    name: 'add-schedule',
    component: () => import('@/views/schedule/control-schedule/AddEditSchedule.vue'),
    meta: {
      title: 'Add schedule ',
      requiresAuth: true,
      action: 'create',
      resource: 'schedule',
      pageTitle: 'schedule',
      breadcrumb: [
        {
          text: 'schedule',
          to: '/schedule',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/schedule/edit/:id',
    name: 'edit-schedule',
    component: () => import('@/views/schedule/control-schedule/AddEditSchedule.vue'),
    meta: {
      title: 'Edit Schedule ',
      requiresAuth: true,
      pageTitle: 'schedule',
      breadcrumb: [
        {
          text: 'schedule',
          to: '/schedule',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
