export default [
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
    name: 'sections',
    component: () => import(
      '@/views/main-certificate/certificate/components/section/Section.vue'
    ),
    meta: {
      title: 'Sections ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Sections',
      pageTitle: 'Sections',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Sections',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/add',
    name: 'add-sections',
    component: () => import(
      '@/views/main-certificate/certificate/components/section/control-section/AddEditSection.vue'
    ),
    meta: {
      title: 'Add sections ',
      requiresAuth: true,
      action: 'create',
      resource: 'Add Sections',
      pageTitle: 'Add Sections',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Sections',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/edit/:id',
    name: 'edit-sections',
    component: () => import(
      '@/views/main-certificate/certificate/components/section/control-section/AddEditSection.vue'
    ),
    meta: {
      title: 'Edit sections ',
      requiresAuth: true,
      pageTitle: 'Edit Sections',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Sections',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
