export default [
  {
    path: '/branch-accounts',
    name: 'branch-accounts',
    component: () => import('@/views/bank-accounts/BranchBankAccounts.vue'),
  },
  {
    path: '/bank-accounts',
    name: 'bank-accounts',
    component: () => import('@/views/bank-accounts/MoneyBankAccount.vue'),
  },
  {
    path: '/currency-accounts',
    name: 'currency-accounts',
    component: () => import('@/views/bank-accounts/CurrencyBankAccounts.vue'),
  },
]
