export default [
  {
    path: '/request-inspection-completed',
    name: 'request-inspection-completed',
    component: () => import('@/views/request-inspection/RequestInspectionCompleted.vue'),
    meta: {
      title: 'Request Inspection completed',
      requiresAuth: true,
      action: 'browes',
      resource: 'request-inspection-completed',
      pageTitle: 'Request Inspection completed',
      breadcrumb: [
        {
          text: 'Request Inspection completed',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-inspection-pending',
    name: 'request-inspection-pending',
    component: () => import('@/views/request-inspection/RequestInspectionPending.vue'),
    meta: {
      title: 'Request Inspection pending',
      requiresAuth: true,
      action: 'browes',
      resource: 'request-inspection-pending',
      pageTitle: 'Request Inspection pending',
      breadcrumb: [
        {
          text: 'Request Inspection pending',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-request-inspection/edit/:id/:name',
    name: 'edit-request-inspection',
    component: () => import(
      '@/views/request-inspection/control-request-inspection/AddEditRequestInspection.vue'
    ),
    meta: {
      title: 'Edit Request Inspection ',
      requiresAuth: true,
      action: 'edit',
      resource: 'Request Inspection',
      pageTitle: 'Request Inspection',
      breadcrumb: [
        {
          text: 'Request Inspection',
          to: '/request-inspection-pending',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
