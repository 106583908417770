export default [
  {
    path: '/service-need-approval',
    name: 'service-need-approval',
    component: () => import('@/views/service-need-approval/ServiceNeedApproval.vue'),
    meta: {
      title: 'Service Need Approval',
      requiresAuth: true,
      action: 'browes',
      resource: 'Service Need Approval',
      pageTitle: 'Service Need Approval',
      breadcrumb: [
        {
          text: 'Service Need Approval',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/approval-need/:id',
    name: 'service-approval',
    component: () => import(
      '@/views/service-need-approval/control-service-need-approval/ApprovalTakeAction.vue'
    ),
    meta: {
      title: 'Service Need Approval',
      requiresAuth: true,
      action: 'create',
      resource: 'service-need-approval',
      pageTitle: 'Service Need Approval',
      breadcrumb: [
        {
          text: 'Service Need Approval',
          to: '/service-need-approval',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/cities/edit/:id',
  //   name: 'edit-city',
  //   component: () => import('@/views/cities/control-city/AddEditCity.vue'),
  //   meta: {
  //     title: 'Edit City ',
  //     requiresAuth: true,
  //     pageTitle: 'Cities',
  //     breadcrumb: [
  //       {
  //         text: 'Cities',
  //         to: '/cities',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
