export default [
  {
    path: '/Departments',
    name: 'departments',
    component: () => import('@/views/departments/Departments.vue'),
    meta: {
      title: 'departments',
      requiresAuth: true,
      action: 'browes',
      resource: 'Departments ',
      pageTitle: 'Departments ',
      breadcrumb: [
        {
          text: 'departments',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/departments/add',
    name: 'add-departments',
    component: () => import('@/views/departments/control-departments/AddEditDepartments.vue'),
    meta: {
      title: 'Add Certification Companies ',
      requiresAuth: true,
      action: 'create',
      resource: 'departments',
      pageTitle: 'departments',
      breadcrumb: [
        {
          text: 'departments',
          to: '/departments',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/departments/edit/:id',
    name: 'edit-departments',
    component: () => import('@/views/departments/control-departments/AddEditDepartments.vue'),
    meta: {
      title: 'Edit departments',
      requiresAuth: true,
      pageTitle: 'departments',
      breadcrumb: [
        {
          text: 'departments',
          to: '/departments',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
