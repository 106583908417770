export default [
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/Product.vue'),
    meta: {
      title: 'product ',
      requiresAuth: true,
      action: 'browes',
      resource: 'product',
      pageTitle: 'product',
      breadcrumb: [
        {
          text: 'product',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/add',
    name: 'add-product',
    component: () => import('@/views/product/control-product/AddEditProduct.vue'),
    meta: {
      title: 'Add product ',
      requiresAuth: true,
      action: 'create',
      resource: 'product',
      pageTitle: 'product',
      breadcrumb: [
        {
          text: 'product',
          to: '/product',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/edit/:id',
    name: 'edit-product',
    component: () => import('@/views/product/control-product/AddEditProduct.vue'),
    meta: {
      title: 'Edit product ',
      requiresAuth: true,
      pageTitle: 'product',
      breadcrumb: [
        {
          text: 'product',
          to: '/product',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
