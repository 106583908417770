export default [
  {
    path: '/mainCertificate/:MCR_id/:Certificate_Name',
    name: 'certificate',
    component: () => import('@/views/main-certificate/certificate/Certificate.vue'),
    meta: {
      title: 'Certificate ',
      requiresAuth: true,
      action: 'browes',
      resource: 'certificate',
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/add/:MCR_id',
    name: 'add-certificate',
    component: () => import(
      '@/views/main-certificate/certificate/control-certificate/AddEditCertificate.vue'
    ),
    meta: {
      title: 'Add Certificate ',
      requiresAuth: true,
      action: 'create',
      resource: 'certificate',
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/:Certificate_Name/edit/:id/',
    name: 'edit-certificate',
    component: () => import(
      '@/views/main-certificate/certificate/control-certificate/AddEditCertificate.vue'
    ),
    meta: {
      title: 'Edit Certificate ',
      requiresAuth: true,
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/EditEngOnCert/:MCR_id/:Certificate_Name/assign/:id',
    name: 'edit-eng-on-cert',
    component: () => import(
      '@/views/main-certificate/certificate/control-certificate/EditEngineersOnCertificate.vue'
    ),
    meta: {
      pageTitle: 'Assign Engineers',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Assign Engineers',
          active: true,
        },
      ],
    },
  },
]
