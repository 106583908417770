export default [
  {
    path: '/Post-entries',
    name: 'Post Entries',
    component: () => import('@/views/accounting/post-entries/PostEntries.vue'),
    meta: {
      title: 'Post Entries',
      requiresAuth: true,
      action: 'browes',
      resource: 'Post Entries',
      pageTitle: 'Post Entries',
      breadcrumb: [
        {
          text: 'Post Entries',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Reverse-entries/:id',
    name: 'Reverse Entries',
    component: () => import('@/views/accounting/post-entries/ReverseEntries.vue'),
    meta: {
      title: 'Reverse Entries',
      requiresAuth: true,
      action: 'browes',
      resource: 'Reverse Entries',
      pageTitle: 'Reverse Entries',
      breadcrumb: [
        {
          text: 'Reverse Entries',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
