export default [
  {
    path: '/audit-not-assigned',
    name: 'audit-not-assigned',
    component: () => import('@/views/audit-not-assigned/AuditNotAssigned.vue'),
    meta: {
      title: 'Audit Not Assigned ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Audit Not Assigned',
      pageTitle: 'Audit Not Assigned',
      breadcrumb: [
        {
          text: 'Audit Not Assigned',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/show-audit/:id',
    name: 'show-audit',
    component: () => import(
      '@/views/audit-not-assigned/control-audit-not-assigned/ShowInformationAudit.vue'
    ),
    meta: {
      title: 'Show Audit',
      requiresAuth: true,
      action: 'browes',
      resource: 'Show Audit',
      pageTitle: 'Show Audit',
      breadcrumb: [
        {
          text: 'Show Audit',
        },
        {
          text: 'Show Audit',
          active: true,
        },
      ],
    },
  },
]
