export default [
  {
    path: '/requests-over-due',
    name: 'requests-over-due',
    component: () => import('@/views/requests/RequestOverDue.vue'),
    meta: {
      title: 'requests ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/RequestHistories/:id',
    name: 'request-histories',
    component: () => import('@/views/requests/RequestHistories.vue'),
    meta: {
      title: 'Requests Histories ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests Histories',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
