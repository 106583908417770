export default [
  {
    path: '/user-site',
    name: 'user-site',
    component: () => import('@/views/user-site/UserSite.vue'),
    meta: {
      title: 'User Site',
      requiresAuth: true,
      action: 'browes',
      resource: 'user-site',
      pageTitle: 'User Site',
      breadcrumb: [
        {
          text: 'User Site',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-site/add',
    name: 'add-user-site',
    component: () => import('@/views/user-site/control-user-site/AddUserSite.vue'),
    meta: {
      title: 'Add User Site ',
      requiresAuth: true,
      action: 'create',
      resource: 'user-site',
      pageTitle: 'User Site',
      breadcrumb: [
        {
          text: 'User Site List',
          to: '/user-Site',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-site/:id/:name',
    name: 'edit-user-site',
    component: () => import('@/views/user-site/control-user-site/AddUserSite.vue'),
    meta: {
      title: 'Edit User Site ',
      requiresAuth: true,
      pageTitle: 'User Site',
      breadcrumb: [
        {
          text: 'User Site',
          to: '/user-site',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-site-performance/:id',
    name: 'user-site-performance',
    component: () => import('@/views/user-site/UserSitePerformance.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'User Performance Evaluation',
      breadcrumb: [
        {
          text: 'User Site',
          to: '/user-site',
        },
        {
          text: 'Performance Evaluation',
          active: true,
        },
      ],
    },
  },
]
