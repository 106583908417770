import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import Axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Invoice from 'vue-invoice'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Toasted from 'vue-toasted'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import VJsoneditor from 'v-jsoneditor'
import AOS from 'aos'

// import Echo from 'laravel-echo'
import VueApexCharts from 'vue-apexcharts'
import axiosSetup from '@/libs/axios-instances'
// import axiosInstances from '@/libs/axios-instances'
import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification' // Import brand icons

import 'aos/dist/aos.css'

// Add the imported icon sets to the library

window.io = require('socket.io-client')

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
/* add icons to the library */
library.add(fas, far, fab)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Axios.defaults.baseURL = 'http://localhost:3000/'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

// register axios apis instances
// Vue.use(axiosInstances)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Toasted)
Vue.use(VueSweetalert2)
Vue.use(VJsoneditor)
Vue.use(Invoice, { store })

// vee validate register
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBIXateUuFJu7bTAJIIAS4bQK26XSIRaHY',
    libraries: 'places',
  },
  installComponents: true,
})

Vue.config.productionTip = false

axiosSetup()

Vue.filter('formatDate', value => {
  let filteredDate = ''
  if (value) {
    const myDate = new Date(value)
    const year = myDate.getUTCFullYear()
    const mth = myDate.getUTCMonth() + 1
    const day = myDate.getUTCDate()
    let hours = myDate.getUTCHours()
    const min = myDate.getUTCMinutes()
    let am = true
    if (hours > 12) {
      am = false
      hours -= 12
    } else if (hours === 12) {
      am = false
    } else if (hours === 0) {
      hours = 12
    }
    const dayNight = am ? 'AM' : 'PM'
    filteredDate = `${day}-${mth}-${year} ${hours}:${min} ${dayNight}`
  }
  return filteredDate
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
AOS.init()
