/* eslint-disable */

import CryptoJS from "crypto-js";

// Encryption
export function encrypted(originalText) {
  const secretKey =
    "4/{,#1X%v'7UUv|}pHejuQ]Kg>1(CnlOO0F^GvF8+z<2&^,'Te-PTLSV:p_p|B783~,rv0y7z<Ojn[0)wlA8/j0m-K(2k1v";

  // Encrypt the text
  const Encryption = CryptoJS.AES.encrypt(originalText, secretKey).toString();

  // Combine encrypted text + secretKey and ensure 150 characters
  const combined = Encryption + secretKey;
  const finalOutput =
    combined.length >= 150
      ? combined.substring(0, 200) // Trim if too long
      : combined.padEnd(200, ""); // Pad if too short

  return finalOutput;
}

// Decryption
export function decrypted(encryptedText) {
  const secretKey =
    "4/{,#1X%v'7UUv|}pHejuQ]Kg>1(CnlOO0F^GvF8+z<2&^,'Te-PTLSV:p_p|B783~,rv0y7z<Ojn[0)wlA8/j0m-K(2k1v";
  // Extract the encrypted part (remove the appended secretKey)
  const extractedEncryption = encryptedText.substring(
    0,
    encryptedText.indexOf(secretKey)
  );
  // Decrypt the extracted part
  const decrypted = CryptoJS.AES.decrypt(
    extractedEncryption,
    secretKey
  ).toString(CryptoJS.enc.Utf8);

  return decrypted;
}
