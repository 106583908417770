export default [
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
    name: 'components',
    component: () => import('@/views/main-certificate/certificate/components/Components.vue'),
    meta: {
      title: 'Components ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Components',
      pageTitle: 'Components',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/add',
    name: 'add-components',
    component: () => import(
      '@/views/main-certificate/certificate/components/control-components/AddEditComponents.vue'
    ),
    meta: {
      title: 'Add components ',
      requiresAuth: true,
      action: 'create',
      resource: 'Add Components',
      pageTitle: 'Add Components',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:id/edit/:name',
    name: 'edit-components',
    component: () => import(
      '@/views/main-certificate/certificate/components/control-components/AddEditComponents.vue'
    ),
    meta: {
      title: 'Edit components ',
      requiresAuth: true,
      pageTitle: 'Edit Components',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
