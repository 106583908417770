export default [
  {
    path: '/requests-unassigned',
    name: 'requests-unassigned',
    component: () => import('@/views/requests/RequestUnassign.vue'),
    meta: {
      title: 'new requests ',
      requiresAuth: true,
      action: 'browes',
      resource: 'New requests',
      pageTitle: 'New requests',
      breadcrumb: [
        {
          text: 'New requests',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
