export default [
  {
    path: '/personnel-evaluation',
    name: 'personnel-evaluation',
    component: () => import('@/views/personnel-evaluation/PersonnelEvaluation.vue'),
    meta: {
      title: 'Personnel Evaluation ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Personnel Evaluation',
      pageTitle: 'Personnel Evaluation',
      breadcrumb: [
        {
          text: 'Personnel Evaluation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/view-personnel-evaluation/:id',
    name: 'view-personnel-evaluation',
    component: () => import('@/views/personnel-evaluation/PersonnelEvaluationView.vue'),
    meta: {
      title: 'Show Personnel Evaluation ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Show Personnel Evaluation',
      pageTitle: 'Show Personnel Evaluation',
      breadcrumb: [
        {
          text: 'Show Personnel Evaluation',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
]
