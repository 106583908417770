export default [
  {
    path: '/office',
    name: 'office',
    component: () => import('@/views/office/Office.vue'),
    meta: {
      title: 'Office ',
      requiresAuth: true,
      action: 'browes',
      resource: 'Office',
      pageTitle: 'Office',
      breadcrumb: [
        {
          text: 'Office',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/office/add',
    name: 'add-office',
    component: () => import('@/views/office/control-office/AddEditOffice.vue'),
    meta: {
      title: 'Add office ',
      requiresAuth: true,
      action: 'create',
      resource: 'office',
      pageTitle: 'office',
      breadcrumb: [
        {
          text: 'office',
          to: '/office',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/office/edit/:id',
    name: 'edit-office',
    component: () => import('@/views/office/control-office/AddEditOffice.vue'),
    meta: {
      title: 'Edit office ',
      requiresAuth: true,
      pageTitle: 'office',
      breadcrumb: [
        {
          text: 'office',
          to: '/office',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
