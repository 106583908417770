export default [
  {
    path: '/product-and-certificate',
    name: 'product-and-certificate',
    component: () => import('@/views/product-and-certificate/ProAndCer.vue'),
    meta: {
      title: 'product and certificate',
      requiresAuth: true,
      action: 'browes',
    },
  },
  {
    path: '/product-certificate',
    name: 'product-certificate',
    component: () => import(
      '@/views/product-and-certificate/control-all-product/ProductCertificate.vue'
    ),
    meta: {
      title: 'product certificate',
      requiresAuth: true,
      action: 'browes',
    },
  },
  {
    path: '/certificate-product',
    name: 'certificate-product',
    component: () => import(
      '@/views/product-and-certificate/control-all-product/CertificateProduct.vue'
    ),
    meta: {
      title: 'certificate product',
      requiresAuth: true,
      action: 'browes',
    },
  },
  {
    path: '/product-certificate/add',
    name: 'add-product-to-certificate',
    component: () => import(
      '@/views/product-and-certificate/control-all-product/control-product-to-certificate/AddEditProductToCertificate.vue'
    ),
    meta: {
      title: 'Add product to certificate',
      requiresAuth: true,
      action: 'create',
      breadcrumb: [
        {
          text: 'product-and-certificate',
          to: '/product-and-certificate',
        },
        {
          text: 'certificate',
          to: '/certificate-product',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-certificate/edit/:id',
    name: 'edit-product-to-certificate',
    component: () => import(
      '@/views/product-and-certificate/control-all-product/control-product-to-certificate/AddEditProductToCertificate.vue'
    ),
    meta: {
      title: 'Edit Post',
      requiresAuth: true,
      pageTitle: 'post',
      breadcrumb: [
        {
          text: 'product-and-certificate',
          to: '/product-and-certificate',
        },
        {
          text: 'certificate',
          to: '/certificate-product',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
