export default [
  {
    path: '/training',
    name: 'training',
    component: () => import('@/views/training/Training.vue'),
    meta: {
      title: 'Training',
      requiresAuth: true,
      action: 'browes',
      resource: 'training',
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Training',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training/add',
    name: 'add-training',
    component: () => import('@/views/training/control-training/AddEditTraining.vue'),
    meta: {
      title: 'Add Training',
      requiresAuth: true,
      action: 'create',
      resource: 'training',
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Training List',
          to: '/training',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training/:id',
    name: 'edit-training',
    component: () => import('@/views/training/control-training/AddEditTraining.vue'),
    meta: {
      title: 'Edit  Training ',
      requiresAuth: true,
      pageTitle: ' Training',
      breadcrumb: [
        {
          text: ' Training',
          to: '/training',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
